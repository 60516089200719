import { alert, confirm, confirmDelete, prompt } from '@/components/dialogs'
import { VueBlockCreateFragment } from './fragments'
import { RuntimeVueBlock } from '@/models'
import CodelessComponent from './components/CodelessComponent.vue'
import Fields from '@/components/form/Fields.vue'
import Field from '@/components/fields/Field.vue'
import FileSelect from '@/components/fields/file/UploadModal.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
import getClient from '@/plugins/vue-apollo/client'
import gql from 'graphql-tag'
import moment from '@/plugins/moment'
import Vue from 'vue'

export interface VueBlockDependencies {
  collections: Record<string, boolean>
  indicators: Record<string, boolean>
  css: Record<string, string>
}

export async function getVueBlockWithData(
  vueBlockId: string,
  viewParams: Record<string, any>
) {
  const client = getClient()
  const { data: result } = await client.query({
    query: gql`
      query getVueBlockWithData($vueBlockId: ID, $params: JSON) {
        blockWithData: vueBlock(vueBlockId: $vueBlockId) {
          ...VueBlockCreate
          script
          renderFn
          compiledStyle
          apiCalls {
            name
          }
          autoUpdateData
          injectNewData
          data(params: $params)
        }
      }
      ${VueBlockCreateFragment}
    `,
    fetchPolicy: 'network-only',
    variables: {
      vueBlockId,
      params: viewParams
    }
  })
  return result.blockWithData as RuntimeVueBlock
}

export async function importVueBlock(
  blockId: string,
  viewParams: Record<string, any>,
  isAdmin: boolean,
  parent: Vue,
  dependencies: VueBlockDependencies
) {
  const block = await getVueBlockWithData(blockId, viewParams)
  if (block.data && block.autoUpdateData) {
    Object.assign(dependencies.collections, block.data.__dependencies.collections)
    Object.assign(dependencies.indicators, block.data.__dependencies.indicators)
  }
  if (block.compiledStyle) dependencies.css[block._id] = block.compiledStyle
  return getContentComponent(
    block,
    viewParams,
    block.data,
    isAdmin,
    parent,
    dependencies
  )
}

export async function getContentComponent(
  block: RuntimeVueBlock,
  viewParams: Record<string, any>,
  data: any,
  isAdmin: boolean,
  parent: Vue,
  dependencies: VueBlockDependencies
) {
  try {
    const AsyncFunction = new Function(
      'return (async function () {}).constructor'
    )()
    const importFn = (blockId: string) =>
      importVueBlock(blockId, viewParams, isAdmin, parent, dependencies)
    const properties = await new AsyncFunction(
      '_',
      'moment',
      'importVueBlock',
      block.script || 'return {}'
    )(_, moment, importFn)
    const codelessMixin = <any>{
      computed: {
        $dialogs: () => ({
          alert,
          confirm,
          confirmDelete,
          prompt
        }),
        $api: () => {
          const apiCalls: Record<string, any> = {}
          for (const apiCall of block.apiCalls) {
            apiCalls[apiCall.name] = (params: Record<string, any>) =>
              //@ts-ignore
              invokeApiCall(block._id, parent.viewParams, apiCall.name, params)
          }
          return apiCalls
        },
        //@ts-ignore
        $params: () => parent.viewParams
      },
      methods: <any>{
        $setParams(newParams: Record<string, any>) {
          parent.$emit('setParams', newParams)
        }
      }
    }

    return {
      ...properties,
      components: {
        ...(properties.components || {}),
        CodelessComponent,
        Fields,
        Field,
        FileSelect,
        draggable,
        apexchart: import('@/plugins/apexcharts').then((v) => v.VueApexCharts)
      },
      name: 'RenderedVueBlock',
      props: properties.props || ['viewParams'],
      mixins: [codelessMixin],
      data: () => data,
      render: new Function(
        block.renderFn!.render || 'with(this){return _c("div", "") }'
      ),
      staticRenderFns: (block.renderFn!.staticRenderFns || []).map(
        (fn: any) => new Function(fn)
      )
    }
  } catch (e) {
    console.error(e)
    return {
      name: 'VueBlockRenderError',
      render(h: any) {
        return h(
          'div',
          {
            class: 'text-center'
          },
          [
            h('v-icon', { props: { size: 96 } }, 'broken_image'),
            isAdmin
              ? h('pre', { class: 'error--text error-display' }, e.message)
              : null
          ]
        )
      }
    }
  }
}

export async function invokeApiCall(
  vueBlockId: string,
  viewParams: Record<string, any>,
  name: string,
  params: Record<string, any>
) {
  const client = getClient()
  const result = await client.mutate({
    mutation: gql`
      mutation ($vueBlockId: ID, $apiCallName: String, $params: JSON) {
        result: invokeVueBlockApiCall(
          vueBlockId: $vueBlockId
          apiCallName: $apiCallName
          params: $params
        )
      }
    `,
    // Parameters
    variables: {
      vueBlockId,
      apiCallName: name,
      params: { ...viewParams, ...params }
    }
  })
  const res = result.data.result
  if (res.success) {
    return res.result
  } else {
    throw new Error(res.error)
  }
}
