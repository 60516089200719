
















































import { Component, Vue, Prop, Watch, Provide } from 'vue-property-decorator'
import { User, ViewItem, RuntimeVueBlock } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Loading from './Loading.vue'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloError } from 'apollo-client'
import { VueBlockCreateFragment } from './fragments'
import _ from 'lodash'
import { getContentComponent, VueBlockDependencies } from './utils'

@Component({
  name: 'BlockView',
  components: {
    Loading,
    ComponentHeader,
    CollectionWatcher
  },
  apollo: {
    block: {
      query: gql`
        query getVueBlock($vueBlockId: ID) {
          block: vueBlock(vueBlockId: $vueBlockId) {
            ...VueBlockCreate
            name
            title
            roles
            script
            renderFn
            compiledStyle
            apiCalls {
              name
            }
          }
        }
        ${VueBlockCreateFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          vueBlockId: this.componentId
        }
      },
      error(e: ApolloError) {
        this.error = e
      }
    },
    blockWithData: {
      query: gql`
        query getVueBlockWithData($vueBlockId: ID, $params: JSON) {
          blockWithData: vueBlock(vueBlockId: $vueBlockId) {
            ...VueBlockCreate
            autoUpdateData
            injectNewData
            data(params: $params)
          }
        }
        ${VueBlockCreateFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          vueBlockId: this.componentId,
          params: this.viewParams
        }
      },
      error(e: ApolloError) {
        this.error = e
      }
    }
  }
})
export default class VueBlockView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem
  @Prop({ type: Boolean, default: false }) showCloseButton!: boolean

  block: RuntimeVueBlock | null = null
  blockWithData: RuntimeVueBlock | null = null
  staticData: Record<string, any> | null = null
  dependencies: VueBlockDependencies = { collections: {}, indicators: {}, css: {} }
  styleTag = '<style></style>'
  error: any = null
  contentComponent = {
    name: 'VueBlockLoading',
    render(h: any) {
      return h(Loading, '')
    }
  }

  @Provide()
  codelessRuntimeVars() {
    return {
      environmentId: this.environmentId,
      viewParams: this.viewParams,
      preview: this.preview,
      editing: this.editing,
      setParams: (params: Record<string, any>) =>
        this.$emit('setParams', params)
    }
  }

  refetchData() {
    return this.$apollo.queries.blockWithData.refetch()
  }

  @Watch('blockWithData')
  onData(blockWithData: RuntimeVueBlock | null) {
    if (!blockWithData || !blockWithData.data) return
    const content = this.$refs.content as any
    if (!this.staticData || blockWithData.injectNewData) {
      this.staticData = blockWithData.data
    } else if (this.$refs.content && blockWithData.autoUpdateData) {
      if (content.onData) content.onData(blockWithData.data)
    }
    if (content && blockWithData.injectNewData) {
      // this.updateContentComponent()
      const content = this.$refs.content as any
      Object.assign(content, blockWithData.data)
      if (content.onData) content.onData(blockWithData.data)
    }
  }

  @Watch('block')
  async updateContentComponent() {
    const block = this.block!
    if (!block || !this.staticData) {
      return (this.contentComponent = {
        name: 'VueBlockError',
        render(h: any) {
          return h(Loading, '')
        }
      })
    }
    const dependencies: VueBlockDependencies = {
      collections: {},
      indicators: {},
      css: {
        [block._id]: block.compiledStyle || block.style || ''
      }
    }
    if (
      this.blockWithData &&
      this.blockWithData.data &&
      this.blockWithData.autoUpdateData
    ) {
      dependencies.collections = this.blockWithData.data.__dependencies.collections
      dependencies.indicators = this.blockWithData.data.__dependencies.indicators
    }
    this.contentComponent = await getContentComponent(
      block,
      this.viewParams,
      this.staticData,
      this.isAdmin,
      this,
      dependencies
    )
    this.styleTag = `<style>${Object.keys(dependencies.css)
      .map((c) => dependencies.css[c])
      .join('\n')}</style>`
    this.dependencies = {
      collections: dependencies.collections,
      indicators: dependencies.indicators,
      css: {}
    }
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }

  get errorMessage() {
    if (!this.error) return ''
    return this.error.message
  }
}
